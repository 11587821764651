import React from 'react'
import Layout from '../../components/layout'
import { NamingsCreator } from '../../components/ChallengeCreators'

const NamingsCreatorPage = ({ learningGoalId }) => (
  <Layout>
    <NamingsCreator learningGoalId={learningGoalId} />
  </Layout>
)

export default NamingsCreatorPage
